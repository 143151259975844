import ConsumerBonusService from "@api/consumer/consumerBonusService";
import { useRole } from "@hooks/userHooks";
import {
    BonusCodeAccess,
    ConsumerBonusCodeFull,
} from "@interfaces/consumer/bonusCodes";
import { fromUtcDate } from "@services/helpers";
import { Form, Formik } from "formik";
import {FC, useCallback} from "react";
import { toastr } from "react-redux-toastr";
import {Col, Row} from "react-bootstrap";
import {FormPageFooter, FormPageHeader} from "@components/falcon/common/forms";
import {BaseInfoBlock, BonusCodeFormValues, editBonusFormValidation, RewardBlock} from "./forms"
import {ConsumerBonusCodeUsesList} from "@components/consumer/bonuses/index";

interface ConsumerBonusCodeInfoProps {
    code: ConsumerBonusCodeFull;
}

interface ConsumerBonusCodeInfoForm {
    code: string;
    maxUseCount: number;
    timeLimited: boolean;
    beginTime?: Date;
    endTime?: Date;
    isActive: boolean;
    access: BonusCodeAccess;
}

const ConsumerBonusCodeInfo: FC<ConsumerBonusCodeInfoProps> = ({ code }) => {

    const value = JSON.parse(code.valueString);
    const itemId = value?.ItemId;
    const itemCount = value?.Count;
    const balanceValue = value?.Value;

    const initial: BonusCodeFormValues = {
        code: code.code,
        count: code.useCount,
        maxUseCount: code.maxUseCount,
        timeLimited: Boolean(code.beginTime || code.endTime),
        isActive: code.isActive,
        beginTime: (code.beginTime && fromUtcDate(code.beginTime).toDate()) || new Date(),
        endTime: (code.endTime && fromUtcDate(code.endTime).toDate()) || new Date(),
        access: code.access,

        itemId: itemId || "",
        itemCount: itemCount || 1,
        money: balanceValue || 0,
        type: code.type
    }

    const canEdit = useRole('consumer.bonus.edit');

    const onSubmit = useCallback(async (values: ConsumerBonusCodeInfoForm) => {

        if (!canEdit) {
            return;
        }

        const api = new ConsumerBonusService();
        const result = await api.patchBonusCode({
            id: code.id,
            isActive: values.isActive,
            code: values.code,
            maxUseCount: values.maxUseCount,
            beginTime: values.timeLimited ? values.beginTime?.toUTCString() : undefined,
            endTime: values.timeLimited ? values.endTime?.toUTCString() : undefined
        });

        if (result.success) {
            toastr.success("Бонусный код", "Код успешно изменен");
        }
        else {
            if (result.errorCode === 'CodeNotFound') {
                toastr.error('Бонусный код', 'Код не найден');
            }
            else {
                toastr.error('Бонусный код', 'Что-то пошло не так');
            }
        }
    }, [canEdit, code.id]);

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={editBonusFormValidation}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <FormPageHeader title="Редактирование бонусного кода" />
                    </Col>
                    <Col lg={8}>
                        <BaseInfoBlock editForm />
                    </Col>
                    <Col lg={4}>
                        <RewardBlock editForm />
                        <ConsumerBonusCodeUsesList />
                    </Col>
                    <Col>
                        <FormPageFooter
                            cancelUrl="/consumer/bonuses/"
                        />
                    </Col>
                </Row>
            </Form>
        </Formik>
    )
}

export default ConsumerBonusCodeInfo;