import React, {FC} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import {Field, useFormikContext} from "formik";
import {useRole} from "@services/hooks";
import {BonusCodeFormValues} from "@components/consumer/bonuses/forms/index";
import ReactDatePicker from "react-datepicker";
import {BonusCodeAccess} from "@interfaces/consumer/bonusCodes";

interface BaseInfoBlockProps {
    editForm?: boolean
}

const BaseInfoBlock: FC<BaseInfoBlockProps> = ({editForm}) => {

    const {
        values,
        setFieldValue,
    } = useFormikContext<BonusCodeFormValues>();

    const canEdit = useRole('consumer.bonus.edit');

    return (
        <Card className="mb-3">
            <Card.Header as="h6" className="bg-body-tertiary">
                Основное
            </Card.Header>
            <Card.Body>
                <Field
                    label='Код'
                    type='text'
                    name='code'
                    placeholder='YOURCODE'
                    disabled={!canEdit}
                    component={FormikRowInput}
                />
                <Field
                    label='Лимит использований'
                    type='number'
                    name='maxUseCount'
                    min={0}
                    placeholder='Название бонуса'
                    component={FormikRowInput}
                    disabled={!canEdit}
                    description="0 - неограничено"
                />
                {editForm ? (
                    <>
                        <Field
                            label='Активно'
                            id='bisActive'
                            name='isActive'
                            type='switch'
                            disabled={!canEdit}
                            component={FormikRowCheckbox}
                        />
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label
                                column
                                className={"label-color"}
                                sm={3}
                            >
                                Количество использований
                            </Form.Label>
                            <Form.Label
                                column
                                className={"label-color"}
                                sm={9}>
                                {values.count}
                            </Form.Label>
                        </Form.Group>
                    </>
                ) : (
                    <Field
                        label='Количество использований'
                        type='number'
                        name='count'
                        min={0}
                        placeholder='Название бонуса'
                        component={FormikRowInput}
                        disabled={!canEdit}
                    />

                )}
                <Form.Group as={Row} className="mb-3">
                    <Form.Label
                        column
                        htmlFor="type"
                        className={"label-color"}
                        sm={3}
                    >
                        Доступность
                    </Form.Label>
                    <Col sm={9} >
                        <Field
                            type='radio'
                            name='type'
                            id='bonus-code-default'
                            component={Form.Check}
                            value={BonusCodeAccess.Default}
                            checked={values.access === BonusCodeAccess.Default}
                            label='В ЛК'
                            inline
                            disabled={editForm} />
                        <Field
                            type='radio'
                            name='type'
                            id='bonus-code-signup'
                            component={Form.Check}
                            value={BonusCodeAccess.SignUp}
                            checked={values.access === BonusCodeAccess.SignUp}
                            label='При регистрации'
                            inline
                            disabled={editForm} />
                    </Col>
                </Form.Group>
                <Field
                    label='Ограничение по времени'
                    type="switch"
                    id="timeLimited"
                    name="timeLimited"
                    disabled={!canEdit}
                    component={FormikRowCheckbox}
                    />
                {values.timeLimited && (
                    <>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label
                                column
                                htmlFor="beginTime"
                                sm={3}
                            >
                                Время начала
                            </Form.Label>
                            <Col sm={9}>
                                <ReactDatePicker
                                    selected={values.beginTime}
                                    id="beginTime"
                                    name="beginTime"
                                    onChange={date => setFieldValue('beginTime', date)}
                                    showTimeInput
                                    timeInputLabel="Время:"
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    placeholderText="Введите дату старта"
                                    className="form-control"
                                    disabled={!canEdit} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label
                                column
                                htmlFor="endTime"
                                sm={3}
                            >
                                Время окончания
                            </Form.Label>
                            <Col sm={9}>
                                <ReactDatePicker
                                    selected={values.endTime}
                                    id="endTime"
                                    name="endTime"
                                    onChange={date => setFieldValue('endTime', date)}
                                    showTimeInput
                                    timeInputLabel="Время:"
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    placeholderText="Введите дату окончания"
                                    className="form-control"
                                    disabled={!canEdit} />
                            </Col>
                        </Form.Group>
                    </>
                )}
            </Card.Body>
        </Card>
    )
}

export default BaseInfoBlock;